"use client";

import Script from "next/script";

import DataLayer from "@client/classes/data-layer/data-layer";

import BenefitsSurvey from "@packages/types/benefits-survey";
import type Events from "@packages/types/events";
import type LeadForm from "@packages/types/lead-form";
import type SecondaryOffersForm from "@packages/types/secondary-offers-form";

type Props = {
  data?:
    | Events.ErrorData
    | Events.NPSRating
    | Events.ThankYouPage
    | BenefitsSurvey.Step
    | LeadForm.Step
    | LeadForm.StepValues
    | SecondaryOffersForm.Step
    | SecondaryOffersForm.StepValues;
  event: string;
  queue?: boolean;
};

export default function Event({ data, event, queue }: Props) {
  return (
    <Script
      id={`analytics-${event}`}
      onReady={() => {
        if (!queue) {
          DataLayer.events.trigger(event, data);
        } else {
          DataLayer.events.queue(event, data);
        }
      }}
    >
      <></>
    </Script>
  );
}
