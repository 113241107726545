import {
  EVENT_COOKIE_CONSENT_SUBMITTED,
  EVENT_COOKIE_CONSENT_VIEWED,
  EVENT_ELIGIBLE_BENEFIT_CLICKED,
  EVENT_ERROR,
  EVENT_JOURNEY_STARTED,
  EVENT_LANDING_PAGE_VIEWED,
  EVENT_SECONDARY_OFFERS_STEP_SUBMITTED,
  EVENT_SECONDARY_OFFER_CLICKED,
  EVENT_STEP_SUBMITTED,
  EVENT_STEP_VIEWED,
  EVENT_THANK_YOU_PAGE_VIEWED,
  LAND,
  LAND_DOMAIN_NAME,
  LAND_SITE_NAME,
} from "@shared/constants";

import { logDebug } from "@shared/functions/log";

import postEvent from "@server/front-end-api/postEvent";

import type DataLayer from "@client/classes/data-layer/data-layer";
import Journey from "@client/classes/models/journey";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";
import type Land from "@packages/types/land";
import type LeadForm from "@packages/types/lead-form";
import type SecondaryOffersForm from "@packages/types/secondary-offers-form";

export const V1_EVENTS = {
  LANDING_PAGE: "landing_page_loaded",
  STEP_SUBMITTED: "step",
  STEP_VIEWED: "page_loaded",
};

export const V2_EVENTS = {
  CLICK: "submit",
  COOKIE_CONSENT_SUBMITTED: "submit",
  ERROR: "error",
  JOURNEY: "journey",
  LANDING_PAGE: "page_load",
  OBJECT_LOADED: "object_load",
  STEP_SUBMITTED: "submit",
  STEP_VIEWED: "wizard",
  THANK_YOU_PAGE: "thank_you_page",
};

export default class LandEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  private dataLayer: typeof DataLayer;

  /**
   * Initialize `LandEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_COOKIE_CONSENT_SUBMITTED,
      this.handleCookieConsentSubmitted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_COOKIE_CONSENT_VIEWED,
      this.handleCookieConsentViewed.bind(this),
    );

    this.dataLayer.events.subscribe(EVENT_ERROR, this.handleError.bind(this));

    this.dataLayer.events.subscribe(
      EVENT_JOURNEY_STARTED,
      this.handleJourneyStarted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_LANDING_PAGE_VIEWED,
      this.handleLandingPageView.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_STEP_VIEWED,
      this.handleStepView.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_STEP_SUBMITTED,
      this.handleStepSubmitted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_SECONDARY_OFFERS_STEP_SUBMITTED,
      this.handleStepSubmitted.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_SECONDARY_OFFER_CLICKED,
      this.handleSecondaryOfferClicked.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_ELIGIBLE_BENEFIT_CLICKED,
      this.handleEligibleBenefitClicked.bind(this),
    );

    this.dataLayer.events.subscribe(
      EVENT_THANK_YOU_PAGE_VIEWED,
      this.handleThankYouPageView.bind(this),
    );

    logDebug({ message: "Class 'LandEvents' initialized" });
  }

  /**
   * Send "cookie consent submitted" data
   */
  private handleCookieConsentSubmitted(
    _: string,
    { data }: Event.Data<Events.CookieConsentSubmitted>,
  ) {
    this.send(V2_EVENTS.COOKIE_CONSENT_SUBMITTED, {
      submit: {
        consent: data.consented,
      },
    });
  }

  /**
   * Send "cookie consent viewed" data
   */
  private handleCookieConsentViewed(_: string, data: Location) {
    this.send(V2_EVENTS.OBJECT_LOADED, {
      object_load: {
        cookie_consent_banner: {
          path: data.pathname,
        },
      },
    });
  }

  /**
   * Send "click event" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleSecondaryOfferClicked(
    _: string,
    { data }: Event.Data<Events.SecondaryOfferClicked>,
  ) {
    // Land v2
    this.send(V2_EVENTS.CLICK, {
      submit: {
        offer_id: data.offerId,
      },
    });
  }

  /**
   * Send "click event" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleEligibleBenefitClicked(
    _: string,
    { data }: Event.Data<Events.EligibleBenefitClicked>,
  ) {
    // Land v2
    this.send(V2_EVENTS.CLICK, {
      submit: {
        survey_id: data.surveyId,
      },
    });
  }

  /**
   * Send "error" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleError(
    _: string,
    { data, location }: Event.Data<Events.ErrorData>,
  ) {
    // Land v2
    this.send(V2_EVENTS.ERROR, {
      error: {
        error_message: data.message,
        error_object: data.object,
        error_type: data.type,
        path: location.pathname,
      },
    });
  }

  /**
   * Send "Journey" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleJourneyStarted(_: string) {
    // Land v2
    this.send(V2_EVENTS.JOURNEY, {
      journey: {
        description: this.dataLayer.journey.getProp<Journey, "description">(
          "description",
        ),
        name: this.dataLayer.journey.getProp<Journey, "name">("name"),
        published:
          this.dataLayer.journey.getProp<Journey, "published">("published") ||
          "",
        slug: this.dataLayer.journey.getProp<Journey, "slug">("slug"),
        updated:
          this.dataLayer.journey.getProp<Journey, "updated">("updated") || "",
        tags: this.dataLayer.journey.getProp<Journey, "tags">("tags"),
      },
    });
  }

  /**
   * Send "landing page view" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleLandingPageView(_: string, data: Location) {
    // Land v1
    this.send(V1_EVENTS.LANDING_PAGE, {
      landing_page: this.dataLayer.journey.getProp<Journey, "variation">(
        "variation",
      ),
      path: data.pathname,
      domain: data.host,
      journey: this.dataLayer.journey.getProp<Journey, "name">("name"),
      host: data.host,
    } as Land.v1.LandingPageLoaded);

    // Land v2
    this.send(V2_EVENTS.LANDING_PAGE, {
      page_load: {
        landing_page: {
          name: this.dataLayer.journey.getProp<Journey, "name">("name") || "",
          path: data.pathname,
          published:
            this.dataLayer.journey.getProp<Journey, "published">("published") ||
            "",
          variation: this.dataLayer.journey.getProp<Journey, "variation">(
            "variation",
          ),
          updated:
            this.dataLayer.journey.getProp<Journey, "updated">("updated") || "",
        },
      },
    });
  }

  /**
   * Send "step submitted" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleStepSubmitted(
    _: string,
    {
      data,
      location,
    }: Event.Data<LeadForm.StepValues | SecondaryOffersForm.StepValues>,
  ) {
    const fields = { ...(data as object) } as Omit<
      Land.v1.Step,
      "flow" | "step" | "stepName" | "submitAction"
    >;
    delete fields.flow;
    delete fields.step;
    delete fields.stepName;
    delete fields.submitAction;

    // Land v1
    this.send(V1_EVENTS.STEP_SUBMITTED, {
      data: fields,
      domain: LAND_DOMAIN_NAME,
      host: location.hostname,
      journey: this.dataLayer.journey.getProp<Journey, "name">("name"),
      path: location.pathname,
      step_name: data.stepName,
      step_number: data.step,
      step: data.step,
    } as Land.v1.StepSubmitted);

    // Land v2
    this.send(V2_EVENTS.STEP_SUBMITTED, {
      submit: {
        data: fields,
        flow: data.flow,
        form: data.stepName,
        submitted: true,
      },
    });
  }

  /**
   * Send "step view" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleStepView(
    _: string,
    { data, location }: Event.Data<LeadForm.Step>,
  ) {
    // Land v1
    this.send(V1_EVENTS.STEP_VIEWED, {
      action: "step loaded",
      object: `Step loaded: ${data.stepName}`,
      page: location.pathname,
      site: LAND_SITE_NAME,
      text: "",
      type: "html",
      path: location.pathname,
      journey: this.dataLayer.journey.getProp<Journey, "name">("name"),
      domain: LAND_DOMAIN_NAME,
      host: location.hostname,
    } as Land.v1.PageLoaded);

    // Land v2
    this.send(V2_EVENTS.STEP_VIEWED, {
      wizard: {
        step: {
          flow: data.flow,
          step_name: data.stepName,
          step_number: data.step,
        },
      },
    });
  }

  /**
   * Send "step view" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleThankYouPageView(
    _: string,
    { data, location }: Event.Data<Events.ThankYouPage>,
  ) {
    // Land v2
    this.send(V2_EVENTS.THANK_YOU_PAGE, {
      thank_you_page: {
        path: location.pathname,
        provider: data.leadSubmission?.data.offers[0]?.provider || "",
        sorted_secondary_offer_ids: data.sortedSecondaryOfferIds || [],
        variation: data.variation,
      },
    });
  }

  /**
   * Send event to Land
   *
   * @param {string} event Name of event being fired
   * @param {object} meta Event meta to send
   */
  private send(
    event: string,
    meta:
      | Land.v1.JourneyLoaded
      | Land.v1.LandingPageLoaded
      | Land.v1.PageLoaded
      | Land.v1.StepSubmitted
      | Land.v2.Click
      | Land.v2.Error
      | Land.v2.Journey
      | Land.v2.ObjectLoad<Land.v2.CookieConsent.Viewed>
      | Land.v2.PageLoad<Land.v2.LandingPage>
      | Land.v2.Submit
      | Land.v2.ThankYouPage
      | Land.v2.Wizard,
  ) {
    postEvent(event, meta);

    this.dataLayer.events.log(event, meta, LAND);
  }
}
