// Usage example
// formatGtmPhoneNumber('333-333-3333') => '+13333333333'
// formatGtmPhoneNumber('1333-333-3333') => '+13333333333'
// formatGtmPhoneNumber('333.333.3333') => '+13333333333'

export default function formatGtmPhoneNumber(phoneNumber: string): string {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Add country code if not already present
  const formatted =
    cleaned.length === 10
      ? `+1${cleaned}`
      : cleaned.startsWith("1")
        ? `+${cleaned}`
        : cleaned;

  return formatted;
}
